import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  Application,
  ApplicationType,
  ApplicationSetting,
  ValueType,
  ApiConnectionType,
  ApiConnection,
  ApplicationDefinition,
  ApplicationReference,
  ModuleApplicationSettingMap,
  Addon,
  ApplicationDefinitionOrganization,
  Organization,
  User,
  Group,
  Integrator,
  OrganizationRole,
  OrganizationRoleUser,
  OrganizationRoleGroup,
  OrganizationRoleIntegrator,
  OrganizationRoleApplication,
  ApplicationRole,
  ApplicationOperation,
  ApplicationOperationRoleAssignment,
  SettingType,
  Environment,
  EnvironmentComponent,
  ActiveEnvironmentComponent,
  EnvironmentComponentRole,
  EnvironmentComponentOperationRoleAssignment,
  MarketPlaceApplication,
  MarketPlaceApplicationVersion,
  EnvironmentComponentSettingValue,
  MarketPlaceAppType,
  ModifyEnvironmentComponentDefinitionDto,
  EnvironmentComponentDefinition,
  RoleType,
  EnvironmentComponentValidation,
  EnvironmentComponentTask,
  EnvironmentTaskScheduler,
  TaskHistoryPage,
  ApplicationReferenceListDto,
  ApplicationListDto,
  FootprintWorkflowsBlobsDataDto,
  CloneApplicationDefinitionRequest,
  ApiConnectionSftpAuthenticationType
} from './application.do';

import { isNil } from 'lodash-es';
import * as moment_ from 'moment';
import { ApplicationContextService } from './application-context.service';
import { Configuration, EDesignerConfigType, IDesignerValidationError } from '../designer-config-service/designer-config.service';
import { IShellDesignerConfig } from '../designer-config-service/shell/shell-designer-config';
import { ILocalizationDesignerConfig } from '../designer-config-service/localization/localization-designer-config';
const moment = moment_;

export const DATEX_APP_API_URL = new InjectionToken<string>('DATEX_APP_API_URL');

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}organizations`;
  }

  getAll(): Observable<Organization[]> {
    const url = `${this.endpointUrl}`;
    return this.http.get<Organization[]>(url);
  }

  get(id: number): Observable<Organization> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<Organization>(url);
  }

  create(org: Organization): Observable<Organization> {
    const url = `${this.endpointUrl}`;
    return this.http.post<Organization>(url, org);
  }

  update(org: Organization): Observable<Organization> {
    const url = `${this.endpointUrl}/${org.id}`;
    return this.http.put<Organization>(url, org);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }
}



@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}authorization`;
  }

  hasStudioAccess(): Observable<boolean> {
    const url = `${this.endpointUrl}/hasStudioAccess`;
    return this.http.get<boolean>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}environments`;
  }

  getAll(organizationId: number): Observable<Environment[]> {
    const url = `${this.endpointUrl}?organizationId=${organizationId}`;
    return this.http.get<Environment[]>(url);
  }

  get(id: number): Observable<Environment> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<Environment>(url);
  }

  getSchedulerForEnvironment(id: number): Observable<EnvironmentTaskScheduler> {
    const url = `${this.endpointUrl}/${id}/schedulerinfo`;
    return this.http.get<EnvironmentTaskScheduler>(url);
  }

  create(env: Environment): Observable<Environment> {
    const url = `${this.endpointUrl}`;
    return this.http.post<Environment>(url, env);
  }

  update(env: Environment): Observable<Environment> {
    const url = `${this.endpointUrl}/${env.id}`;
    return this.http.put<Environment>(url, env);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  isNameUnique(name: string, organizationId: number): Observable<boolean> {
    const url = `${this.endpointUrl}/isnameunique?name=${name}&organizationId=${organizationId}`;
    return this.http.post<boolean>(url, null);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MarketPlaceApplicationsService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}marketplaceapplications`;
  }

  get(id: number): Observable<MarketPlaceApplication> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<MarketPlaceApplication>(url);
  }

  getInstallableApplicationsForOrganization(organizationId: number): Observable<MarketPlaceApplication[]> {
    const url = `${this.endpointUrl}/installable?organizationId=${organizationId}`;
    return this.http.get<MarketPlaceApplication[]>(url);
  }

  getInstallableFootprintWorkflows(organizationId: number): Observable<MarketPlaceApplication[]> {
    const url = `${this.endpointUrl}/installablefootprintworkflows?organizationId=${organizationId}`;
    return this.http.get<MarketPlaceApplication[]>(url);
  }

  getPublic(): Observable<MarketPlaceApplication[]> {
    const url = `${this.endpointUrl}/public`;
    return this.http.get<MarketPlaceApplication[]>(url);
  }

  getApplicationVersions(id: number): Observable<MarketPlaceApplicationVersion[]> {
    const url = `${this.endpointUrl}/${id}/versions`;
    return this.http.get<MarketPlaceApplicationVersion[]>(url);
  }

  getFootprintWorkflowsVersions(id: number): Observable<MarketPlaceApplicationVersion[]> {
    const url = `${this.endpointUrl}/${id}/footprintworkflowsversions`;
    return this.http.get<MarketPlaceApplicationVersion[]>(url);
  }

  // temporairy for the scheduler for now
  createApplicationVersion(id: number, version: { releaseNotes: string, versionCode: string, versionName: string, containerImageUrl: string }) {
    const url = `${this.endpointUrl}/${id}/versions`;
    return this.http.post<MarketPlaceApplicationVersion>(url, version);
  }
  deleteApplicationVersion(id: number, versionId: number) {
    const url = `${this.endpointUrl}/${id}/versions/${versionId}`;
    return this.http.delete(url);
  }

  getFootprintWorkflowsDataExists(id: number, versionId: number): Observable<boolean> {
    const url = `${this.endpointUrl}/${id}/versions/${versionId}/footprintworkflowsdataexists`;
    return this.http.get<boolean>(url);
  }

  getFootprintWorkflowsData(id: number, versionId: number): Observable<FootprintWorkflowsBlobsDataDto> {
    const url = `${this.endpointUrl}/${id}/versions/${versionId}/footprintworkflowsdata`;
    return this.http.get<FootprintWorkflowsBlobsDataDto>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentComponentDefinitionsService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}environmentcomponentdefinitions`;
  }

  getAll(environmentId: number): Observable<EnvironmentComponentDefinition[]> {
    const url = `${this.endpointUrl}?environmentId=${environmentId}`;
    return this.http.get<EnvironmentComponentDefinition[]>(url);
  }

  getAllComponents(environmentComponentDefinitionId: number): Observable<EnvironmentComponent[]> {
    const url = `${this.endpointUrl}/${environmentComponentDefinitionId}/components`;
    return this.http.get<EnvironmentComponent[]>(url);
  }

  get(id: number): Observable<EnvironmentComponentDefinition> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<EnvironmentComponentDefinition>(url);
  }

  create(env: ModifyEnvironmentComponentDefinitionDto): Observable<EnvironmentComponentDefinition> {
    const url = `${this.endpointUrl}`;
    return this.http.post<EnvironmentComponentDefinition>(url, env);
  }

  addVersion(id: number, marketPlaceApplicationVersionId: number): Observable<EnvironmentComponent> {
    const url = `${this.endpointUrl}/${id}/addVersion/${marketPlaceApplicationVersionId}`;
    return this.http.post<EnvironmentComponent>(url, null);
  }

  update(env: ModifyEnvironmentComponentDefinitionDto): Observable<EnvironmentComponentDefinition> {
    const url = `${this.endpointUrl}/${env.id}`;
    return this.http.put<EnvironmentComponentDefinition>(url, env);
  }

  deploy(id: number, componentId: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}/deploycomponent/${componentId}`;
    return this.http.put<void>(url, null);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  isUrlUnique(url: string, environmentId: number, componentDefinitionId?: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.endpointUrl}/isurlunique?url=${url}&environmentId=${environmentId}` + (componentDefinitionId ? `&componentDefinitionId=${componentDefinitionId}` : ''), null);
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentComponentsService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}environmentcomponents`;
  }

  get(id: number): Observable<EnvironmentComponent> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<EnvironmentComponent>(url);
  }

  getActives(): Observable<ActiveEnvironmentComponent[]> {
    const url = `${this.endpointUrl}/active`;
    return this.http.get<ActiveEnvironmentComponent[]>(url);
  }

  isValid(id: number): Observable<EnvironmentComponentValidation> {
    const url = `${this.endpointUrl}/${id}/isValid`;
    return this.http.get<EnvironmentComponentValidation>(url);
  }
  // create(env: EnvironmentComponent): Observable<EnvironmentComponent> {
  //   const url = `${this.endpointUrl}`;
  //   return this.http.post<EnvironmentComponent>(url, env);
  // }

  // update(env: EnvironmentComponent): Observable<EnvironmentComponent> {
  //   const url = `${this.endpointUrl}/${env.id}`;
  //   return this.http.put<EnvironmentComponent>(url, env);
  // }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  getSettings(id: number): Observable<EnvironmentComponentSettingValue[]> {
    const url = `${this.endpointUrl}/${id}/settings`;
    return this.http.get<EnvironmentComponentSettingValue[]>(url);
  }

  updateSettings(id: number, instance: EnvironmentComponentSettingValue[]): Observable<void> {
    const url = `${this.endpointUrl}/${id}/settings`;
    return this.http.put<void>(url, instance);
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentComponentRoleService {

  protected get endpointUrl(): string {
    return `${this.apiUrl}environmentcomponents`;
  }

  constructor(
    private http: HttpClient,
    @Inject(DATEX_APP_API_URL) private apiUrl: string) {
  }

  getEnvironmentComponentRole(componentId: number, roleId): Observable<EnvironmentComponentRole> {
    return this.http.get<EnvironmentComponentRole>(`${this.endpointUrl}/${componentId}/roles/${roleId}`);
  }

  getEnvironmentComponentRoles(componentId: number): Observable<EnvironmentComponentRole[]> {
    return this.http.get<EnvironmentComponentRole[]>(`${this.endpointUrl}/${componentId}/roles`);
  }

  addRoles(componentId: number, roles: OrganizationRole[]): Observable<EnvironmentComponentRole[]> {
    const url = `${this.endpointUrl}/${componentId}/roles`;
    return this.http.post<EnvironmentComponentRole[]>(url, roles.map(x => x.id));
  }

  removeRole(componentId: number, roleId: number): Observable<void> {
    const url = `${this.endpointUrl}/${componentId}/role/${roleId}`;
    return this.http.delete<void>(url);
  }

  getOperationRoles(componentId: number, operationId: number): Observable<EnvironmentComponentOperationRoleAssignment[]> {
    return this.http.get<EnvironmentComponentOperationRoleAssignment[]>(`${this.endpointUrl}/${componentId}/operations/${operationId}/roles`);
  }

  getEnvironmentComponentRoleAssignments(componentId: number): Observable<EnvironmentComponentOperationRoleAssignment[]> {
    return this.http.get<EnvironmentComponentOperationRoleAssignment[]>(`${this.endpointUrl}/${componentId}/roleassignments`);
  }

  getAssignedOperationsForRole(componentId: number, roleId: number): Observable<EnvironmentComponentOperationRoleAssignment[]> {
    return this.http.get<EnvironmentComponentOperationRoleAssignment[]>(`${this.endpointUrl}/${componentId}/roles/${roleId}/operations`);
  }

  addOperationsAssignmentsForRole(componentId: number, roleId: number, operations: ApplicationOperation[]): Observable<EnvironmentComponentOperationRoleAssignment[]> {
    const url = `${this.endpointUrl}/${componentId}/roles/${roleId}/operations`;
    return this.http.post<EnvironmentComponentOperationRoleAssignment[]>(url, operations.map(x => x.id));
  }

  removeOperationRoleAssignment(componentId: number, operationId: number, roleId: number): Observable<void> {
    const url = `${this.endpointUrl}/${componentId}/operations/${operationId}/roles/${roleId}`;
    return this.http.delete<void>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}users`;
  }

  getAll(organizationId: number): Observable<User[]> {
    const url = `${this.endpointUrl}?organizationId=${organizationId}`;
    return this.http.get<User[]>(url);
  }

  get(id: number): Observable<User> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<User>(url);
  }

  create(users: User[]): Observable<User[]> {
    const url = `${this.endpointUrl}`;
    return this.http.post<User[]>(url, users);
  }

  update(user: User): Observable<User> {
    const url = `${this.endpointUrl}/${user.id}`;
    return this.http.put<User>(url, user);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }
}



@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}groups`;
  }

  getAll(organizationId: number): Observable<Group[]> {
    const url = `${this.endpointUrl}?organizationId=${organizationId}`;
    return this.http.get<Group[]>(url);
  }

  get(id: number): Observable<Group> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<Group>(url);
  }

  create(groups: Group[]): Observable<Group[]> {
    const url = `${this.endpointUrl}`;
    return this.http.post<Group[]>(url, groups);
  }

  update(group: Group): Observable<Group> {
    const url = `${this.endpointUrl}/${group.id}`;
    return this.http.put<Group>(url, group);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class IntegratorsService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}integrators`;
  }

  getAll(organizationId: number): Observable<Integrator[]> {
    const url = `${this.endpointUrl}?organizationId=${organizationId}`;
    return this.http.get<Integrator[]>(url);
  }

  get(id: number): Observable<Integrator> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<Integrator>(url);
  }

  create(integrators: Integrator[]): Observable<Integrator[]> {
    const url = `${this.endpointUrl}`;
    return this.http.post<Integrator[]>(url, integrators);
  }

  update(integrator: Integrator): Observable<Integrator> {
    const url = `${this.endpointUrl}/${integrator.id}`;
    return this.http.put<Integrator>(url, integrator);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationDefinitionService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}applicationdefinitions`;
  }

  getAll(applicationTypeIds?: number[], organizationId?: number): Observable<ApplicationDefinition[]> {
    let url = `${this.endpointUrl}?`;
    if (applicationTypeIds) {
      for (const id of applicationTypeIds) {
        url += `&applicationTypeIds=${id}`;
      }
    }
    if (organizationId) {
      url += `&organizationId=${organizationId}`;
    }
    return this.http.get<ApplicationDefinition[]>(url);
  }

  get(id: number): Observable<ApplicationDefinition> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<ApplicationDefinition>(url);
  }

  create(app: ApplicationDefinition): Observable<ApplicationDefinition> {
    const url = `${this.endpointUrl}`;
    return this.http.post<ApplicationDefinition>(url, app);
  }

  update(app: ApplicationDefinition): Observable<ApplicationDefinition> {
    const url = `${this.endpointUrl}/${app.id}`;
    return this.http.put<ApplicationDefinition>(url, app);
  }

  clone(id: number, app: CloneApplicationDefinitionRequest): Observable<ApplicationDefinition> {
    const url = `${this.endpointUrl}/${id}/clone`;
    return this.http.post<ApplicationDefinition>(url, app);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  deleteFootprint(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}/footprint`;
    return this.http.delete<void>(url);
  }

  isNameUnique(applicationTypeId: number, name: string, skipAppDefId?: number, organizationId?: number): Observable<boolean> {
    let url = `${this.endpointUrl}/isnameunique?applicationTypeId=${applicationTypeId}&name=${name}`;

    if (isNil(skipAppDefId) === false) {
      url += `&skipapplicationdefinitionid=${skipAppDefId}`;
    }

    if (isNil(organizationId) === false) {
      url += `&organizationId=${organizationId}`;
    }

    return this.http.post<boolean>(url, null);
  }

  isIdentifierUnique(identifier: string, skipAppDefId?: number): Observable<boolean> {
    let url = `${this.endpointUrl}/isidentifierunique?identifier=${identifier}`;
    if (isNil(skipAppDefId) === false) {
      url += `&skipapplicationdefinitionid=${skipAppDefId}`;
    }
    return this.http.post<boolean>(url, null);
  }

  isOwner(id: number): Observable<boolean> {
    const url = `${this.endpointUrl}/${id}/isowner`;
    return this.http.get<boolean>(url);
  }

  deleteOldArchives(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}/deleteoldarchives`;
    return this.http.post<void>(url, null);
  }

  provision(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}/provision`;
    return this.http.post<void>(url, null);
  }
}


@Injectable({
  providedIn: 'root'
})
export class ApplicationDefinitionOrganizationService {
  protected endpointUrl: string;
  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}applicationdefinitionorganizations`;
  }

  create(applicationDefinitionOrganization: ApplicationDefinitionOrganization): Observable<ApplicationDefinitionOrganization> {
    const url = `${this.endpointUrl}`;
    return this.http.post<ApplicationDefinitionOrganization>(url, applicationDefinitionOrganization);
  }

  delete(applicationDefinitionId: number, organizationId: number): Observable<void> {
    const url = `${this.endpointUrl}/applicationdefinition/${applicationDefinitionId}/organization/${organizationId}`;
    return this.http.delete<void>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AddonsService {
  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}addons`;
  }

  getAll(applicationTypeId: number, organizationId?: number): Observable<Addon[]> {
    const url = `${this.endpointUrl}?applicationTypeId=${applicationTypeId}&organizationId=${organizationId}`;
    return this.http.get<Addon[]>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  protected endpointUrl: string;

  constructor(
    private http: HttpClient,
    private applicationContextService: ApplicationContextService,
    @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}applications`;
  }

  preview(id: number): Observable<Application> {
    const url = `${this.endpointUrl}/${id}/preview`;
    return this.http.get<Application>(url);
  }

  getAll(): Observable<Application[]> {
    const url = `${this.endpointUrl}?applicationdefinitionid=${this.applicationContextService.applicationDefinitionId}`;
    return this.http.get<Application[]>(url);
  }

  getEditableAndPublishedBranches(): Observable<Application[]> {
    const url = `${this.endpointUrl}/GetEditableAndPublishedBranches?applicationdefinitionid=${this.applicationContextService.applicationDefinitionId}`;
    return this.http.get<Application[]>(url);
  }

  getApplicationList(): Observable<ApplicationListDto[]> {
    const url = `${this.endpointUrl}/GetApplicationList?applicationdefinitionid=${this.applicationContextService.applicationDefinitionId}`;
    return this.http.get<ApplicationListDto[]>(url);
  }

  getAllForOrganizations(organizationId: number): Observable<Application[]> {
    const url = `${this.endpointUrl}/GetApplicationsForOrganization?organizationId=${organizationId}`;
    return this.http.get<Application[]>(url);
  }

  getAllModules(applicationTypeId: number, id: number): Observable<Application[]> {
    const url = `${this.endpointUrl}/modules?applicationTypeId=${applicationTypeId}&applicationId=${id}`;
    return this.http.get<Application[]>(url);
  }

  getPublished(): Observable<Application[]> {
    const url = `${this.endpointUrl}/published`;
    return this.http.get<Application[]>(url);
  }

  get(id: number): Observable<Application> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<Application>(url);
  }

  getShell(id: number): Observable<Configuration<IShellDesignerConfig>> {
    const url = `${this.endpointUrl}/${id}/shell`;
    return this.http.get<Configuration<IShellDesignerConfig>>(url);
  }

  getLocalization(id: number): Observable<Configuration<ILocalizationDesignerConfig>> {
    const url = `${this.endpointUrl}/${id}/localization`;
    return this.http.get<Configuration<ILocalizationDesignerConfig>>(url);
  }

  getConfigId(id: number, type: EDesignerConfigType): Observable<number> {
    const url = `${this.endpointUrl}/${id}/${type}/configId`;
    return this.http.get<number>(url);
  }

  publish(id: number, publishingDescription: string, version: string): Observable<Application> {
    const url = `${this.endpointUrl}/${id}/publish?releaseNotes=${publishingDescription}&version=${version}`;
    return this.http.post<Application>(url, null);
  }

  convertToDraft(id: number): Observable<Application> {
    const url = `${this.endpointUrl}/${id}/converttodraft`;
    return this.http.post<Application>(url, null);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  validate(id: number): Observable<IDesignerValidationError[]> {
    return this.http.post<IDesignerValidationError[]>(`${this.endpointUrl}/${id}/validate`, null);
  }

  isReferenced(id: number): Observable<boolean> {
    const url = `${this.endpointUrl}/${id}/isreferenced`;
    return this.http.get<boolean>(url);
  }

  referencedFrom(id: number): Observable<Application[]> {
    const url = `${this.endpointUrl}/${id}/referencedfrom`;
    return this.http.get<Application[]>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentComponentTasksService {
  protected get endpointUrl(): string {
    return `${this.apiUrl}environmentComponents`;
  }

  constructor(
    private http: HttpClient,
    @Inject(DATEX_APP_API_URL) private apiUrl: string) {
  }

  getScheduledTasks(componentId: number): Observable<EnvironmentComponentTask[]> {
    return this.http.get<EnvironmentComponentTask[]>(`${this.endpointUrl}/${componentId}/scheduledtasks`);
  }

  getScheduledTask(componentId: number, name: string): Observable<EnvironmentComponentTask> {
    return this.getScheduledTasks(componentId).pipe(
      map(x => x.find(y => y.name === name))
    );
  }

  getTaskHistory(componentId: number, name: string, skip: number, take: number, filterText: string): Observable<TaskHistoryPage> {
    return this.http.get<TaskHistoryPage>(`${this.endpointUrl}/${componentId}/scheduledtasks/history?name=${name}&skip=${skip}&take=${take}&filterText=${filterText ?? ''}`);
  }

  create(componentId: number, task: EnvironmentComponentTask): Observable<void> {
    return this.http.post<void>(`${this.endpointUrl}/${componentId}/scheduledtasks`, task);
  }

  update(componentId: number, task: EnvironmentComponentTask): Observable<void> {
    return this.http.put<void>(`${this.endpointUrl}/${componentId}/scheduledtasks?name=${task.name}`, task);
  }

  runTask(componentId: number, task: EnvironmentComponentTask): Observable<void> {
    return this.http.post<void>(`${this.endpointUrl}/${componentId}/scheduledtasks/runtask?name=${task.name}`, null);
  }

  deleteTask(componentId: number, task: EnvironmentComponentTask): Observable<void> {
    return this.http.delete<void>(`${this.endpointUrl}/${componentId}/scheduledtasks?name=${task.name}`);
  }

  activateTask(componentId: number, name: string): Observable<void> {
    return this.http.post<void>(`${this.endpointUrl}/${componentId}/scheduledtasks/activatetask?name=${name}`, null);
  }

  deactivateTask(componentId: number, name: string): Observable<void> {
    return this.http.post<void>(`${this.endpointUrl}/${componentId}/scheduledtasks/deactivatetask?name=${name}`, null);
  }

  cancelRun(componentId: number, taskName: string, runId: string): Observable<void> {
    return this.http.post<void>(`${this.endpointUrl}/${componentId}/scheduledtasks/cancelrun?name=${taskName}&runId=${runId}`, null);
  }

  isNameUnique(componentId: number, name: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.endpointUrl}/${componentId}/scheduledtasks/isnameunique?name=${name}`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingService {
  protected get endpointUrl(): string {
    return `${this.apiUrl}applications/${this.applicationContextService.applicationId}/settings`;
  }

  constructor(
    private http: HttpClient,
    private applicationContextService: ApplicationContextService,
    @Inject(DATEX_APP_API_URL) private apiUrl: string) {
  }

  getAll(applicationId?: number): Observable<ApplicationSetting[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}/settings`
      : this.endpointUrl;
    return this.http.get<ApplicationSetting[]>(endpoint);
  }

  getAllWithoutJsonForApiConnection(applicationId?: number): Observable<ApplicationSetting[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}/settings`
      : this.endpointUrl;
    return this.http.get<ApplicationSetting[]>(`${endpoint}/WithoutJsonForApiConnection`);
  }

  getModulesApplicationSettingsMap(applicationId?: number): Observable<ModuleApplicationSettingMap[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}/settings`
      : this.endpointUrl;
    return this.http.get<ModuleApplicationSettingMap[]>(`${endpoint}/modulesapplicationsettingsmap`);
  }

  getAllByTypeWithoutJsonForApiConnection(settingTypeId: number, applicationId?: number): Observable<ApplicationSetting[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}/settings`
      : this.endpointUrl;
    const url = `${endpoint}/WithoutJsonForApiConnection?settingTypeId=${settingTypeId}`;
    return this.http.get<ApplicationSetting[]>(url);
  }

  get(id: number): Observable<ApplicationSetting> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<ApplicationSetting>(url);
  }

  getWithoutJsonForApiConnection(id: number): Observable<ApplicationSetting> {
    const url = `${this.endpointUrl}/WithoutJsonForApiConnection/${id}`;
    return this.http.get<ApplicationSetting>(url);
  }

  getByName(name: string): Observable<ApplicationSetting> {
    const url = `${this.endpointUrl}/name/${name}`;
    return this.http.get<ApplicationSetting>(url);
  }

  getByNameWithoutJsonForApiConnection(name: string): Observable<ApplicationSetting> {
    const url = `${this.endpointUrl}/WithoutJsonForApiConnection/name/${name}`;
    return this.http.get<ApplicationSetting>(url);
  }

  create(setting: ApplicationSetting): Observable<ApplicationSetting> {
    return this.http.post<ApplicationSetting>(this.endpointUrl, setting);
  }

  update(id: number, setting: ApplicationSetting): Observable<ApplicationSetting> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.put<ApplicationSetting>(url, setting);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }
}

class BaseOrganizationRoleService {

  protected get endpointUrl(): string {
    return `${this.apiUrl}organizations/${this.applicationContextService.currentOrganization$.getValue()?.id}/${this.controller}`;
  }

  constructor(
    private http: HttpClient,
    private applicationContextService: ApplicationContextService,
    @Inject(DATEX_APP_API_URL) private apiUrl: string,
    private controller: string) {
  }

  getAll(): Observable<OrganizationRole[]> {
    return this.http.get<OrganizationRole[]>(this.endpointUrl);
  }

  getAssignedUsers(roleId): Observable<OrganizationRoleUser[]> {
    return this.http.get<OrganizationRoleUser[]>(`${this.endpointUrl}/${roleId}/users`);
  }

  getAssignedGroups(roleId): Observable<OrganizationRoleGroup[]> {
    return this.http.get<OrganizationRoleGroup[]>(`${this.endpointUrl}/${roleId}/groups`);
  }

  getAssignedIntegrators(roleId): Observable<OrganizationRoleIntegrator[]> {
    return this.http.get<OrganizationRoleIntegrator[]>(`${this.endpointUrl}/${roleId}/integrators`);
  }


  getAssignedApplications(roleId): Observable<OrganizationRoleApplication[]> {
    return this.http.get<OrganizationRoleApplication[]>(`${this.endpointUrl}/${roleId}/applications`);
  }

  assignUsers(role: OrganizationRole, users: User[]): Observable<OrganizationRoleUser[]> {
    const url = `${this.endpointUrl}/${role.id}/users`;
    return this.http.post<OrganizationRoleUser[]>(url, users.map(x => x.id));
  }

  unassignUser(assignment: OrganizationRoleUser): Observable<void> {
    const url = `${this.endpointUrl}/${assignment.roleId}/users/${assignment.userId}`;
    return this.http.delete<void>(url);
  }

  assignGroups(role: OrganizationRole, groups: Group[]): Observable<OrganizationRoleGroup[]> {
    const url = `${this.endpointUrl}/${role.id}/groups`;
    return this.http.post<OrganizationRoleGroup[]>(url, groups.map(x => x.id));
  }

  unassignGroup(assignment: OrganizationRoleGroup): Observable<void> {
    const url = `${this.endpointUrl}/${assignment.roleId}/groups/${assignment.groupId}`;
    return this.http.delete<void>(url);
  }

  assignIntegrators(role: OrganizationRole, integrators: Integrator[]): Observable<OrganizationRoleIntegrator[]> {
    const url = `${this.endpointUrl}/${role.id}/integrators`;
    return this.http.post<OrganizationRoleIntegrator[]>(url, integrators.map(x => x.id));
  }

  unassignIntegrator(assignment: OrganizationRoleIntegrator): Observable<void> {
    const url = `${this.endpointUrl}/${assignment.roleId}/integrators/${assignment.integratorId}`;
    return this.http.delete<void>(url);
  }

  get(id: number): Observable<OrganizationRole> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<OrganizationRole>(url);
  }

  create(role: OrganizationRole): Observable<OrganizationRole> {
    return this.http.post<OrganizationRole>(this.endpointUrl, role);
  }

  update(role: OrganizationRole): Observable<OrganizationRole> {
    const url = `${this.endpointUrl}/${role.id}`;
    return this.http.put<OrganizationRole>(url, role);
  }

  delete(role: OrganizationRole): Observable<void> {
    const url = `${this.endpointUrl}/${role.id}`;
    return this.http.delete<void>(url);
  }
}

export interface IOrganizationRoleService {
  getAll(): Observable<OrganizationRole[]>;
  getAssignedUsers(roleId): Observable<OrganizationRoleUser[]>;
  getAssignedGroups(roleId): Observable<OrganizationRoleGroup[]>;
  getAssignedIntegrators(roleId): Observable<OrganizationRoleIntegrator[]>;
  getAssignedApplications(roleId): Observable<OrganizationRoleApplication[]>;
  assignUsers(role: OrganizationRole, users: User[]): Observable<OrganizationRoleUser[]>;
  unassignUser(assignment: OrganizationRoleUser): Observable<void>;
  assignGroups(role: OrganizationRole, groups: Group[]): Observable<OrganizationRoleGroup[]>;
  unassignGroup(assignment: OrganizationRoleGroup): Observable<void>;
  assignIntegrators(role: OrganizationRole, integrators: Integrator[]): Observable<OrganizationRoleIntegrator[]>;
  unassignIntegrator(assignment: OrganizationRoleIntegrator): Observable<void>;
  get(id: number): Observable<OrganizationRole>;
  create(role: OrganizationRole): Observable<OrganizationRole>;
  update(role: OrganizationRole): Observable<OrganizationRole>;
  delete(role: OrganizationRole): Observable<void>;
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationRoleService extends BaseOrganizationRoleService implements IOrganizationRoleService {
  constructor(
    http: HttpClient,
    applicationContextService: ApplicationContextService,
    @Inject(DATEX_APP_API_URL) apiUrl: string) {
    super(http, applicationContextService, apiUrl, 'roles');
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationDataAccessGroupService extends BaseOrganizationRoleService implements IOrganizationRoleService {
  constructor(
    http: HttpClient,
    applicationContextService: ApplicationContextService,
    @Inject(DATEX_APP_API_URL) apiUrl: string) {
    super(http, applicationContextService, apiUrl, 'OrganizationDataAccessGroups');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationRoleService {

  protected get endpointUrl(): string {
    return `${this.apiUrl}applications/${this.applicationContextService.applicationId}`;
  }

  constructor(
    private http: HttpClient,
    private applicationContextService: ApplicationContextService,
    @Inject(DATEX_APP_API_URL) private apiUrl: string) {
  }

  getApplicationRole(roleId): Observable<ApplicationRole> {
    return this.http.get<ApplicationRole>(`${this.endpointUrl}/roles/${roleId}`);
  }

  getApplicationRoles(applicationId?: number): Observable<ApplicationRole[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}`
      : this.endpointUrl;
    return this.http.get<ApplicationRole[]>(`${endpoint}/roles`);
  }

  addRoles(roles: OrganizationRole[]): Observable<ApplicationRole[]> {
    const url = `${this.endpointUrl}/roles`;
    return this.http.post<ApplicationRole[]>(url, roles.map(x => x.id));
  }

  removeRole(roleId: number): Observable<void> {
    const url = `${this.endpointUrl}/role/${roleId}`;
    return this.http.delete<void>(url);
  }

  getOperations(applicationId?: number): Observable<ApplicationOperation[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}`
      : this.endpointUrl;
    return this.http.get<ApplicationOperation[]>(`${endpoint}/operations`);
  }

  getOperation(operationId: number): Observable<ApplicationOperation> {
    return this.http.get<ApplicationOperation>(`${this.endpointUrl}/operations/${operationId}`);
  }

  createOperation(operation: ApplicationOperation): Observable<ApplicationOperation> {
    return this.http.post<ApplicationOperation>(`${this.endpointUrl}/operations`, operation);
  }

  updateOperation(operation: ApplicationOperation): Observable<ApplicationOperation> {
    return this.http.put<ApplicationOperation>(`${this.endpointUrl}/operations/${operation.id}`, operation);
  }

  deleteOperation(operationId: number): Observable<void> {
    return this.http.delete<void>(`${this.endpointUrl}/operations/${operationId}`);
  }

  getOperationRoles(operationId: number, applicationId?: number): Observable<ApplicationOperationRoleAssignment[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}`
      : this.endpointUrl;
    return this.http.get<ApplicationOperationRoleAssignment[]>(`${endpoint}/operations/${operationId}/roles`);
  }

  getApplicationRoleAssignments(applicationId?: number): Observable<ApplicationOperationRoleAssignment[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}`
      : this.endpointUrl;
    return this.http.get<ApplicationOperationRoleAssignment[]>(`${endpoint}/roleassignments`);
  }

  getAssignedOperationsForRole(roleId: number): Observable<ApplicationOperationRoleAssignment[]> {
    return this.http.get<ApplicationOperationRoleAssignment[]>(`${this.endpointUrl}/roles/${roleId}/operations`);
  }

  addOperationRoleAssignment(operationId: number, roleId: number): Observable<ApplicationOperationRoleAssignment> {
    const url = `${this.endpointUrl}/operations/${operationId}/roles/${roleId}`;
    return this.http.post<ApplicationOperationRoleAssignment>(url, null);
  }

  addOperationRoleAssignments(operationId: number, roles: ApplicationRole[]): Observable<ApplicationOperationRoleAssignment[]> {
    const url = `${this.endpointUrl}/operations/${operationId}/roles`;
    return this.http.post<ApplicationOperationRoleAssignment[]>(url, roles.map(x => x.organizationRoleId));
  }

  removeOperationRoleAssignment(operationId: number, roleId: number): Observable<void> {
    const url = `${this.endpointUrl}/operations/${operationId}/roles/${roleId}`;
    return this.http.delete<void>(url);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationReferenceService {

  protected get endpointUrl(): string {
    return `${this.apiUrl}applications/${this.applicationContextService.applicationId}/references`;
  }

  constructor(
    private http: HttpClient,
    private applicationContextService: ApplicationContextService,
    @Inject(DATEX_APP_API_URL) private apiUrl: string) {
  }

  getAllDirectReferences(applicationId?: number): Observable<ApplicationReference[]> {
    const endpoint = applicationId
      ? `${this.apiUrl}applications/${applicationId}/references`
      : this.endpointUrl;
    return this.http.get<ApplicationReference[]>(`${endpoint}/directReferences`);
  }

  getAllIndirectReferences(): Observable<ApplicationReference[]> {
    return this.http.get<ApplicationReference[]>(`${this.endpointUrl}/indirectReferences`);
  }

  getReferenceList(): Observable<ApplicationReferenceListDto[]> {
    return this.http.get<ApplicationReferenceListDto[]>(`${this.endpointUrl}/referenceList`);
  }

  getReference(id: number): Observable<ApplicationReference> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<ApplicationReference>(url);
  }

  createDirectReference(instance: ApplicationReference): Observable<ApplicationReference> {
    return this.http.post<ApplicationReference>(this.endpointUrl, instance);
  }

  deleteDirectReference(ids: Array<number>): Observable<void> {
    const url = `${this.endpointUrl}/delete`;
    return this.http.patch<void>(url, ids);
  }

  updateReferenceSettings(id: number, settings: Array<unknown>): Observable<void> {
    const url = `${this.endpointUrl}/${id}/settings`;
    return this.http.patch<void>(url, settings);
  }

  upgrade(): Observable<void> {
    const url = `${this.endpointUrl}/upgrade`;
    return this.http.post<void>(url, null);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiConnectionService {

  protected endpointUrl: string;

  constructor(private http: HttpClient, @Inject(DATEX_APP_API_URL) private apiUrl: string) {
    this.endpointUrl = `${this.apiUrl}apiconnections`;
  }

  getViewAll(apiConnectionTypeId: number = null, organizationId: number = null): Observable<ApiConnection[]> {
    const url = `${this.endpointUrl}/view?&apiConnectionTypeId=${apiConnectionTypeId ? apiConnectionTypeId : ''}&organizationId=${organizationId ? organizationId : ''}`;
    return this.http.get<ApiConnection[]>(url);
  }

  getViewById(id: number): Observable<ApiConnection> {
    const url = `${this.endpointUrl}/view/${id}`;
    return this.http.get<ApiConnection>(url);
  }

  getById(id: number): Observable<ApiConnection> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.get<ApiConnection>(url);
  }


  create(apiConnection: ApiConnection): Observable<ApiConnection> {
    return this.http.post<ApiConnection>(this.endpointUrl, apiConnection);
  }

  update(apiConnection: ApiConnection): Observable<ApiConnection> {
    const url = `${this.endpointUrl}/${apiConnection.id}`;
    return this.http.put<ApiConnection>(url, apiConnection);
  }

  delete(id: number): Observable<void> {
    const url = `${this.endpointUrl}/${id}`;
    return this.http.delete<void>(url);
  }
}

export class ApplicationTypeNom {

  static values: ApplicationType[] = [
    {
      id: 1,
      name: 'Web Application'
    },
    {
      id: 2,
      name: 'Mobile Application'
    },
    {
      id: 3,
      name: 'Component package'
    },
    {
      id: 6,
      name: 'API Application'
    },
    {
      id: 7,
      name: 'Portal Application'
    }
  ];

  public static get(): ApplicationType[] {
    return this.values;
  }

  public static getName(applicationTypeId: number): string {
    return this.values.find(item => item.id === applicationTypeId)?.name;
  }

  public static web(): ApplicationType {
    return this.values[0];
  }

  public static componentModule(): ApplicationType {
    return this.values[2];
  }

  public static api(): ApplicationType {
    return this.values[3];
  }

  public static portal(): ApplicationType {
    return this.values[4];
  }

  public static applicationGroup(): number {
    return 1;
  }

  public static componentModuleGroup(): number {
    return 2;
  }
}

export class RoleTypeNom {

  static values: RoleType[] = [
    {
      id: 1,
      name: 'Role'
    },
    {
      id: 2,
      name: 'Data access group'
    }
  ];

  public static getName(roleTypeId: number): string {
    return this.values.find(item => item.id === roleTypeId)?.name;
  }

  public static role(): RoleType {
    return this.values[0];
  }

  public static dataAccessGroup(): RoleType {
    return this.values[1];
  }
}


export class SettingTypeNom {

  static values: SettingType[] = [
    {
      id: 1,
      name: 'Scalar'
    },
    {
      id: 2,
      name: 'Connection'
    }
  ];

  public static get(): SettingType[] {
    return this.values;
  }

  public static connection(): SettingType {
    return this.values[0];
  }

  public static scalar(): SettingType {
    return this.values[1];
  }
}

export class ApiConnectionTypeNom {

  static values: ApiConnectionType[] = [
    {
      id: 1,
      name: 'FootprintApi'
    },
    {
      id: 7,
      name: 'IntegrationHubApi'
    },
    {
      id: 8,
      name: 'MongoDb'
    },
    {
      id: 9,
      name: 'Amqp'
    },
    {
      id: 10,
      name: 'Sftp'
    },
    {
      id: 11,
      name: 'Tcp'
    }
  ];

  public static get(): ApiConnectionType[] {
    return this.values;
  }

  public static exists(id: number): boolean {
    return !!this.values.find(c => c.id === id);
  }

  public static footPrintApi(): ApiConnectionType {
    return this.values[0];
  }

  public static integrationHubApi(): ApiConnectionType {
    return this.values[1];
  }

  public static mongoDb(): ApiConnectionType {
    return this.values[2];
  }

  public static amqp(): ApiConnectionType {
    return this.values[3];
  }

  public static sftp(): ApiConnectionType {
    return this.values[4];
  }

  public static tcp(): ApiConnectionType {
    return this.values[5];
  }
}

export class ValueTypeNom {

  static values: ValueType[] = [
    {
      id: 2,
      name: 'boolean'
    },
    {
      id: 3,
      name: 'string'
    },
    {
      id: 4,
      name: 'number'
    },
    {
      id: 5,
      name: 'date'
    }
    ,
    {
      id: 6,
      name: 'time'
    }
  ];

  public static get(): ValueType[] {
    return this.values;
  }
  public static exists(id: number): boolean {
    return !!this.values.find(c => c.id === id);
  }
  public static boolean(): ValueType {
    return this.values[0];
  }
  public static string(): ValueType {
    return this.values[1];
  }
  public static number(): ValueType {
    return this.values[2];
  }
  public static date(): ValueType {
    return this.values[3];
  }
  public static time(): ValueType {
    return this.values[4];
  }

  public static jsonToString(valueTypeId: number, json: unknown): string {
    if (valueTypeId === this.boolean().id) {
      return `${json.toString()}`;
    } else if (valueTypeId === this.string().id) {
      return `${json}`;
    } else if (valueTypeId === this.number().id) {
      return `${json.toString()}`;
    } else if (valueTypeId === this.date().id) {
      return `${moment.utc(json).format('l, LT')}`;
    } else if (valueTypeId === this.time().id) {
      return `${moment.utc(json).format('LT')}`;
    }
  }
}

export class MarketPlaceAppTypeNom {
  static values: MarketPlaceAppType[] = [
    {
      id: 1,
      name: 'Wavelength Application'
    },
    {
      id: 2,
      name: 'Footprint Api'
    },
    {
      id: 3,
      name: 'Wavelength Core'
    }
  ];

  public static get(): MarketPlaceAppType[] {
    return this.values;
  }

  public static getName(applicationTypeId: number): string {
    return this.values.find(item => item.id === applicationTypeId)?.name;
  }

  public static wavelength(): MarketPlaceAppType {
    return this.values[0];
  }

  public static footprintApi(): MarketPlaceAppType {
    return this.values[1];
  }

  public static taskSchedulerApi(): MarketPlaceAppType {
    return this.values[2];
  }

  public static wavelengthGroup(): number {
    return 1;
  }

  public static footprintApiGroup(): number {
    return 2;
  }

  public static taskSchedulerApiGroup(): number {
    return 3;
  }
}

export class ApiConnectionSftpAuthenticationTypeNom {

  static values: ApiConnectionSftpAuthenticationType[] = [
    {
      id: 1,
      name: 'By password'
    },
    {
      id: 2,
      name: 'By key file'
    }
  ];

  public static get(): ApiConnectionSftpAuthenticationType[] {
    return this.values;
  }

  public static byPassword(): ApiConnectionSftpAuthenticationType {
    return this.values[0];
  }

  public static byKeyFile(): ApiConnectionSftpAuthenticationType {
    return this.values[1];
  }
}


