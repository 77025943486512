
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateAdapterOptions } from '@angular/material-moment-adapter';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';

//// Third party
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {
  NgxMatMomentModule,
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  NgxMatMomentDateAdapterOptions
} from '@angular-material-components/moment-adapter';

import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ValidationErrorsComponent } from './validation-errors/validation-errors.component';
import { DesignerValidationErrorsComponent } from '../common/designer-validation-errors/designer-validation-errors.component';
import { FormFieldShellComponent } from './form-field-shell/form-field-shell.component';
import { ValueControlComponent } from './value-control/value-control.component';
import { NumberboxComponent } from './numberbox/numberbox.component';
import { DateboxComponent } from './datebox/datebox.component';
import { TimeboxComponent } from './timebox/timebox.component';
import { ValidNameValidatorDirective } from './directives/valid-name.directive';
import { JsonformatterDirective } from './directives/jsonformatter.directive';
import { FillPipe } from './directives/fill.pipe';
import { OverlayComponent } from './overlay/overlay.component';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { DATEX_APP_API_URL } from './services/application.service';
import { environment } from '../../environments/environment';
import { CdkTableModule } from '@angular/cdk/table';
import { GridComponent } from './grid/grid.component';
import { MonacoEditorComponent } from './monaco-editor/monaco-editor.component';
import { CronTimePickerComponent } from './cron-time-picker/cron-time-picker.component';
import { CronEditorComponent } from './cron-editor/cron-editor.component';
import { ApiConnectionControlComponent } from './api-connection-control/api-connection-control.component';
import { CronPipe } from './directives/cron.pipe';
import { ConfirmStringDialogComponent } from './confirm-string-dialog/confirm-string-dialog.component';
import { AutoFocusDirective } from '../common/directives/autofocus.directive';
import { InputFieldsComponent } from './input-fields/input-fields.component';
import { CodeInputComponent } from './code-editor/code-input/code-input.component';
import { ExprInputComponent } from './code-editor/expr-input/expr-input.component';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ImageViewerModule } from './image-viewer/image-viewer.module';
import { DateFormatPipe } from './directives/date-format.pipe';
import { TimePeriodPipe } from './directives/time-period.pipe';
import { CopilotAnalysisComponent } from './code-editor/copilot-analysis/copilot-analysis.component';
import { OperationSelectFormComponent } from './operation-select-form/operation-select-form.component';
import { RoleSelectFormComponent } from './role-select-form/role-select-form.component';
import { RequestsInterceptor } from '../interceptors/requests.interceptor';
import { UniqueSettingNameValidatorDirective } from '../studio/common/directives/unique-setting-name.directive';
import { ForbiddenNameValidatorDirective } from './directives/forbidden-name.directive';

const momentDateAdapterConfig: MatMomentDateAdapterOptions = {
  useUtc: true
};

const ngxMomentDateAdapterConfig: NgxMatMomentDateAdapterOptions = {
  useUtc: true
};

@NgModule({
  imports: [
    ImageViewerModule.forRoot({
      containerBackgroundColor: '#fff',
      allowFullscreen: true,
      btnIcons: {
        fullscreen: 'icon icon-ic_fluent_full_screen_maximize_20_regular',
        zoomOut: 'icon icon-ic_fluent_subtract_20_regular',
        zoomIn: 'icon icon-ic_fluent_add_20_regular',
        rotateCounterClockwise: 'icon icon-ic_fluent_arrow_rotate_counterclockwise_20_regular',
        rotateClockwise: 'icon icon-ic_fluent_arrow_rotate_clockwise_20_regular'
      },
      btnShow: {
        zoomIn: true,
        zoomOut: true,
        rotateClockwise: true,
        rotateCounterClockwise: true
      }
    }
    ),
    CodemirrorModule,
    AngularSignaturePadModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,

    DragDropModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatListModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTableModule,

    CdkTableModule,

    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    ReactiveFormsModule,
  ],
  declarations: [
    MessageDialogComponent,
    ConfirmStringDialogComponent,
    DesignerValidationErrorsComponent,
    ValidationErrorsComponent,
    FormFieldShellComponent,
    ValueControlComponent,
    ApiConnectionControlComponent,
    NumberboxComponent,
    DateboxComponent,
    TimeboxComponent,
    ValidNameValidatorDirective,
    ForbiddenNameValidatorDirective,
    UniqueSettingNameValidatorDirective,
    JsonformatterDirective,
    FillPipe,
    CronPipe,
    DateFormatPipe,
    TimePeriodPipe,
    OverlayComponent,
    GridComponent,
    MonacoEditorComponent,
    CronEditorComponent,
    CronTimePickerComponent,
    InputFieldsComponent,
    AutoFocusDirective,
    ExprInputComponent,
    CodeInputComponent,
    CopilotAnalysisComponent,
    OperationSelectFormComponent,
    RoleSelectFormComponent
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: momentDateAdapterConfig },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: ngxMomentDateAdapterConfig },
    { provide: DATEX_APP_API_URL, useValue: environment.datexApplicationApiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: RequestsInterceptor, multi: true },
  ],
  exports: [
    // Re-exporting modules so when other modules import the sharedmodule
    // they have access to those module as well
    // https://angular.io/guide/sharing-ngmodules
    ImageViewerModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    CodemirrorModule,
    AngularSignaturePadModule,

    LoadingBarHttpClientModule,

    DragDropModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatListModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTableModule,

    CdkTableModule,

    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,

    MessageDialogComponent,
    ConfirmStringDialogComponent,
    ValidationErrorsComponent,
    DesignerValidationErrorsComponent,
    FormFieldShellComponent,
    ValueControlComponent,
    ApiConnectionControlComponent,
    NumberboxComponent,
    DateboxComponent,
    TimeboxComponent,
    ValidNameValidatorDirective,
    ForbiddenNameValidatorDirective,
    UniqueSettingNameValidatorDirective,
    JsonformatterDirective,
    FillPipe,
    CronPipe,
    DateFormatPipe,
    TimePeriodPipe,
    OverlayComponent,
    GridComponent,
    MonacoEditorComponent,
    InputFieldsComponent,
    ReactiveFormsModule,
    CronEditorComponent,
    CronTimePickerComponent,
    AutoFocusDirective,
    ExprInputComponent,
    CodeInputComponent,
    OperationSelectFormComponent,
    RoleSelectFormComponent
  ]
})
export class SharedModule { }
