// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authRedirectUri: '/',
  authPostLogoutRedirectUri: '/',
  datexApplicationApiUrl: '/api/',
  integrationHubConfigApiUrl: 'https://datex-integrationhub-configuration-api.azurewebsites.net/configapi/',
  integrationHubConfigurationApiScopes: ['api://2e136a93-d5da-4a55-82cb-348f3bffaa47/.default'],
  legacyConnectorsConfigApiUrl: 'https://datex-file-watcher-configuration-api.azurewebsites.net/',
  legacyConnectorsConfigApiScopes: ['api://07e502df-42c8-4dd8-ac90-b362249cc86e/.default'],
  integrationHubApiUrl: 'https://api-dev.datexintegrationhub.com/api/',
  integrationHubApiScopes: ['api://1e98f08c-7fe1-44c7-861a-7af633bd3b7f/.default'],
  integrationHubLoggingApiUrl: 'https://datexintegrationhub-logging.azurewebsites.net/hubapi/',
  integrationHubLoggingApiScopes: ['api://def9860e-6b99-47d1-bf5f-36fe82c7a900/.default'],
  datexAlertApiUrl: 'https://datex-alertservice-api.azurewebsites.net/odata/',
  datexAlertApiScopes: ['api://d97ca448-6d11-4a89-a83b-3c9300f61799/.default'],
  datexAlertRecommndedSolutionApiUrl: 'https://ca-alerts-suggestion-api-dev.braveocean-c0e93f1e.eastus.azurecontainerapps.io/',
  datexAlertRecommndedSolutionApiScopes: ['api://aa75e592-ccc6-45f8-ab34-59b467a7d29d/.default'],
  kustoQueryApiUrl: '',
  kustoQueryApiScopes: [],
  footPrintAdaptorApiUrl: 'https://datexintegrationhub-footprint.azurewebsites.net/api/',
  footPrintAdaptorApiScopes: ['api://dbb0a371-a05d-443a-a051-8ba60f86a516/.default'],
  shopifyAdaptorApiUrl: 'https://datexintegrationhub-shopify.azurewebsites.net/api/',
  shopifyAdaptorApiScopes: ['api://dbb0a371-a05d-443a-a051-8ba60f86a516/.default'],
  wooCommerceAdaptorApiUrl: 'https://datexintegrationhub-woocommerce.azurewebsites.net/api/',
  wooCommerceAdaptorApiScopes: ['api://dbb0a371-a05d-443a-a051-8ba60f86a516/.default'],
  bigCommerceAdaptorApiUrl: 'https://datexintegrationhub-bigcommerce.azurewebsites.net/api/',
  bigCommerceAdaptorApiScopes: ['api://dbb0a371-a05d-443a-a051-8ba60f86a516/.default'],
  mesciusLicenseKey: '*.wavelength.host,232864259645381#B14TeF5RIp4MypGOpdlYCFnUtZlMZhkRwJUd5E4Z5wUY4hTR8FDSZdmeqhTSXNXM5YGR6FWM6YzVJdTS0JUaslEbWhFb5czdt5EaFdHckRVQ5RFRH3yM73yLXhGeMpWeFZ4ZShmdIFGVrYWMDNWS6hHdVl7S7U5Z5skdqF4UxpkRxE4NChGa5o5L5U4bBpkMQlzZrR4Q9ETdZBlb8l4d0hncSF4YQlWMtJkdhlWRrYTNplWcylleRZXRwlWTWxWcQxWelh6VMhEcatEMHNDdIFFb65WUXFUOMJkYLJWQZJDOCR7QaZTVQFEUmljSnBFWiojITJCLiQTNwQUN4kjNiojIIJCLzgTO9QjMyYzM0IicfJye35XX3JiVMtkTiojIDJCLiUjVgMlS4J7bwVmUlZXa4NWQiojIOJyebpjIkJHUiwiIxATNyITMggjMwEDNyAjMiojI4J7QiwiI4N7bo9Ca4dmblxWZ6F6duoiI0IyctRkIsICelRXYEJiOiEmTDJCLiEDOzUDN6kTNyQjN8IzMyIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRp5U7UUW4Q6QhlUMKJVaI5Ee9lDVh5kS4UXRPh7T6I4LwVXSrZDa4ZVbLZWNnV6aUxGU8gzSrVXWIhzVC56c7AzbjhjWHNTNBVDTuVmYKpFOTR5crJldUBHSXJUZSZ7QEdzUI5zNKx',
  ediLogicAppTriggerUrl: 'https://prod-48.eastus.logic.azure.com:443/workflows/dacd63bbf9ec4e9d9716c787c52f91f6/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=kq7bReStnY2aPBR2ZrrmrZKooAowLM-6IGQvT5sYHnU',
  wavelengthAccountConnectorUrl: 'https://wavelength-connector-edi-dev.eastus.cloudapp.azure.com/',
  authtok: 'gJxjmNebq3g0M9XIEcXHa2PiODl8roVO',
  copilotApiUrl: 'https://copilot.wavelength.host/api/codecopilot/analyze/',
  copilotApiScopes: ['api://b24fc21e-e3f5-48c1-9769-77ed844e7b66/.default'],
  icmApiUrl: 'https://icm.wavelength.host/odata/',
  icmApiScopes: ['api://ad5d6ff0-895d-4d96-aa09-62ddc99c68de/.default'],
  ediPublic: '',
  ediPrivate: 'https://wavelength-connector-edi-dev.eastus.cloudapp.azure.com/AS2Ingress',
  datexDefaultAlertMessageTemplate: 'https://datexintegrationhub.blob.core.windows.net/datex-alert-services/html-email-templates/IntegrationHubBigEcommerceOrderUpdate.html'
};
